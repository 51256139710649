<nav id="cart-overview"
     class="pointer-events-auto flex max-h-[100%] flex-col fixed right-0 top-0 z-[1035] h-screen w-60 translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:-translate-x-0 dark:bg-zinc-800"
     data-te-sidenav-init
     data-te-sidenav-hidden="true"
     data-te-sidenav-right="true"
     data-te-sidenav-width="380">
    <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 pl-8 dark:border-opacity-50">
        <app-text class="text-xl font-medium text-left"
                  [value]="'Кошничка'"></app-text>
        <!--Close button-->
        <button type="button"
                class="rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none float-right"
                data-te-sidenav-toggle-ref
                data-te-target="#cart-overview"
                aria-controls="cart-overview"
                aria-haspopup="true"
                aria-label="Close cart overview">
            <svg xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke-width="1.5"
                 stroke="currentColor"
                 class="h-6 w-6">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"/>
            </svg>
        </button>
    </div>
    @if (orderItems.length === 0) {
        <div class="px-5 lg:px-8 my-auto text-center text-xl">
            <app-text [value]="'Твојата кошничка е празна'"></app-text>
            <div class="pt-3">
                <app-primary-button [class]="'text-lg xl:text-xl font-medium'" [value]="'КОН ПРОДАВНИЦА'"
                                    (click)="goToUrl('/shop')"></app-primary-button>
            </div>
        </div>
    } @else {
        <div class="relative overflow-y-auto h-3/4">
            @for (orderItem of orderItems; track orderItems; let i = $index) {
                @if (orderItem && orderItem.product) {
                    <div class="p-5">
                        <div class="flex justify-between">
                            <div class="flex">
                                <app-image [class]="'w-20 lg:w-28 max-w-28'"
                                           [src]="orderItem.product.images[0]"
                                           [alt]="orderItem.product.altImages[0]"></app-image>
                                <div class="text-left px-1.5 lg:my-auto lg:text-sm">
                                    <app-text [class]="'text-sm font-medium text-left'"
                                              [value]="orderItem?.product?.name"></app-text>
                                    <app-text class="text-sm text-left"
                                              [value]="((orderItem?.product?.price || 0) * (orderItem?.quantity || 0)) + ' ден.'">
                                    </app-text>
                                </div>
                            </div>
                            <div class="flex grid-cols-2 gap-7">
                                <div class="grid my-auto w-24">
                                    <app-quantity-selector
                                            (quantityChange)="updateOrderItemQuantity(i , $event)"
                                            [quantity]="orderItem.quantity"></app-quantity-selector>
                                    <button [class]="'text-sm lg:text-md text-center underline px-2'"
                                            (click)="removeItemFromLocalStorage(i)">Oтстрани
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
            <hr class="h-px my-2 bg-gray-200 border-0">
            <div class="text-right px-3">
                @if (orderPrice < 1500) {
                    <app-text class="text-gray-400 text-xs pb-2"
                              [value]="'*гратис достава за нарачка над 1500 ден.'"></app-text>
                    <app-text class="text-gray-400"
                              [value]="'Достава: ' + deliveryExpenses + ' ден.'"></app-text>
                } @else {
                    <app-text class="text-gray-400 text-sm pb-5"
                              [value]="'*достава гратис.'"></app-text>
                }
            </div>
        </div>
        <div class="fixed bottom-3 px-5 lg:px-8 w-full">
            <app-secondary-button
                    class="flex justify-center items-center"
                    value="Види ја кошничката"
                    (click)="goToUrl('/cart')"></app-secondary-button>
            <app-primary-button
                    class="pt-3 pb-5 flex justify-center items-center"
                    [value]="'Наплата - ' + totalPrice + ' ден.'"
                    (click)="goToUrl('/checkout')"></app-primary-button>
        </div>
    }
</nav>