import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangeEvent, CKEditorModule} from '@ckeditor/ckeditor5-angular';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-rich-text',
    templateUrl: './rich-text.component.html',
    styleUrls: ['./rich-text.component.css'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, CKEditorModule, FormsModule, ReactiveFormsModule],
    standalone: true
})
export class RichTextComponent implements AfterViewInit {
    @ViewChild('editorMenuBarElement') private editorMenuBar!: ElementRef<HTMLDivElement>;
    @Input() control: UntypedFormControl = new UntypedFormControl;
    @Input() editorContent: string = '';
    @Output() editorDataChange = new EventEmitter<string>();

    public isLayoutReady = false;
    public Editor = ClassicEditor;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    public ngAfterViewInit(): void {

        this.isLayoutReady = true;
        this.changeDetector.detectChanges();

        const editorElement = document.querySelector('#editorElement') as HTMLElement | null;

        if (editorElement && this.editorContent) {
            this.control.setValue(this.editorContent);
        }
    }

    public onChange({editor}: ChangeEvent) {
        const data = editor.getData();
        this.editorDataChange.emit(data);
    }
}