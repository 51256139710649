<div class="bg-white pt-32 lg:pt-52 pb-14 mx-auto px-6 md:px-12 lg:px-20">
    <div class="max-w-4xl mx-auto">
        <h1 class="text-3xl font-bold text-green-600 mb-6">Како До Финансиска Слобода - Изгради Успешен Бизнис со
            AloeVeraGel.mk</h1>
        <p class="text-gray-700 text-lg mb-6">
            Нашиот бизнис модел е совршена комбинација од личен успех, тимска работа и висококвалитетни производи. Со
            <strong>AloeVeraGel.mk</strong> и Forever Living Products, ја имате можноста да изградите стабилна кариера и
            да бидете дел од глобална заедница која го поддржува здравјето, убавината и просперитетот.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mb-4">Зошто да не изберете нас?</h2>
        <p class="text-gray-700 mb-6">Со нашата уникатна платформа, може да остварите три клучни цели во животот:</p>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                <h3 class="text-xl font-bold text-green-600 mb-3">Здравје</h3>
                <p class="text-gray-700">Внесете позитивна промена во животите на клиентите, помагајќи им да се
                    чувствуваат подобро и посреќно.</p>
            </div>
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                <h3 class="text-xl font-bold text-green-600 mb-3">Финансиска Слобода</h3>
                <p class="text-gray-700">Бидете сопственици на вашиот приход. Заработете од вашиот труд и успехот на
                    вашиот тим.</p>
            </div>
            <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                <h3 class="text-xl font-bold text-green-600 mb-3">Контрола на Времето</h3>
                <p class="text-gray-700">Организирајте го вашиот ден како што вие сакате со флексибилен распоред.</p>
            </div>
        </div>

        <h2 class="text-2xl font-semibold text-gray-800 mt-10 mb-4">Како до Успех?</h2>
        <ol class="list-decimal list-inside text-gray-700 mb-6">
            <li class="mb-3"><strong>Започнете го вашиот пат:</strong> Споделете ги придобивките од нашите производи со
                пријателите и семејството.
            </li>
            <li class="mb-3"><strong>Создадете свој тим:</strong> Изградете мрежа од задоволни клиенти и соработници.
            </li>
            <li class="mb-3"><strong>Остварете лидерска позиција:</strong> Водете го вашиот тим кон успех и стабилен
                бизнис.
            </li>
        </ol>

        <blockquote class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 italic mb-6">
            „Оваа можност ни го смени животот. Од мал дополнителен приход, успеавме да изградиме независност и
            финансиска слобода. Придружете ни се – заедно ќе создадеме успешна приказна!“
        </blockquote>

        <p class="text-gray-700 leading-relaxed mb-6 text-center">
            Контакт телефон
            <a href="tel:073857133" class="text-primary underline">
                <strong>073/857-133</strong>
            </a>
        </p>

        <div class="lg:w-60 mx-auto">
            <app-primary-button
                    [value]="'Контактирај нè денес'"
                    (click)="goToUrl(router, 'contact-us')"
            ></app-primary-button>
        </div>
    </div>
</div>

<app-toast [message]="toastMessage"></app-toast>
<app-footer></app-footer>