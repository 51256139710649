import {Component, Input, OnInit} from '@angular/core';
import {Carousel, initTE,} from "tw-elements";
import {LinkModule} from "../link/link.module";
import {environment} from "../../environments/environment";
import {InfoBannerSlide} from "../../models/slide";

@Component({
    selector: 'app-info-banner',
    standalone: true,
    imports: [
        LinkModule
    ],
    templateUrl: './info-banner.component.html',
    styleUrl: './info-banner.component.css'
})
export class InfoBannerComponent implements OnInit {

    @Input()
    showInfoBanner: boolean = true;

    slides: InfoBannerSlide[] = [
        {
            message: "Попуст од 5% на ",
            link: {
                text: "сите производи",
                route: environment.navigation.shop
            },
        },
        {
            message: "Контакт телефон",
            phone: "073857133",
            displayPhone: "073/857-133",
        },
        {
            message: "",
            link: {
                text: "Бизнис можност со алое вера",
                route: environment.navigation.brandAmbassador
            },

        },
        {
            message: "Бесплатна достава за нарачки над 1500 ден."
        }
    ];

    ngOnInit(): void {
        initTE({Carousel});
    }

}