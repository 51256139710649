<div class="relative min-h-screen h-fit p-10 lg:p-16 pt-[8.5rem] lg:pt-52 md:w-4/5 mx-auto">
    @if (orderItems.length > 0) {
        <div @slideDown>
            <app-text class="text-center text-4xl lg:text-7xl font-black" [value]="'КОШНИЧКА'"></app-text>
        </div>
        <div class="hidden lg:flex justify-between pt-3 lg:pt-16 text-gray-400">
            <app-text [value]="'ПРОДУКТИ'"></app-text>
            <div class="flex space-x-20">
                <app-text [value]="'КОЛИЧИНА'"></app-text>
                <app-text [value]="'ВКУПНО'"></app-text>
            </div>
        </div>
        <hr class="hidden lg:flex h-px my-2 bg-gray-400 border-0">
        @for (orderItem of orderItems; track orderItems; let i = $index) {
            @if (orderItem && orderItem.product && orderItem.product.price && orderItem.quantity) {
                <div class="pt-3">
                    <app-card
                            (quantityChange)="updateOrderItemQuantity(i , $event)"
                            [quantity]="orderItem.quantity"
                            [orderItem]="orderItem"
                            [layout]="'horizontal'"
                            (removeItemEvent)="removeItemFromLocalStorage(i)"
                            [orderItemPrice]="orderItem.product.price * orderItem.quantity"></app-card>
                </div>
            }
        }
        <hr class="h-px my-2 bg-gray-400 border-0">
        <div class="text-left lg:text-right">
            @if (orderPrice < 1500) {
                <app-text class="text-gray-400 text-xs pb-5"
                          [value]="'*гратис достава за нарачка над 1500 ден.'"></app-text>
                <app-text class="text-lg text-gray-400"
                          [value]="'Достава: ' + deliveryExpenses + ' ден.'"></app-text>
            } @else {
                <app-text class="text-gray-400 text-sm pb-5"
                          [value]="'*достава гратис.'"></app-text>
            }
            <app-text class="text-2xl"
                      [value]="'ВКУПНО: ' + totalPrice + ' ден.'"></app-text>
            <div class="pt-5 md:w-3/5 lg:w-1/5 md:float-right mb-10">
                <app-primary-button [value]="'КУПИ СЕГА'" (click)="goToUrl(router, '/checkout')"></app-primary-button>
            </div>
        </div>
    } @else {
        <div class="absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-medium gap-5">
            <app-text [class]="'text-xl md:text-3xl'" [value]="'КОШНИЧКА'"></app-text>
            <app-text [class]="'py-3 md:text-xl'" [value]="'Твојата кошничка е празна'"></app-text>
            <app-primary-button [value]="'КОН ПРОДАВНИЦА'" (click)="goToUrl(router,'/shop')"></app-primary-button>
        </div>
    }
</div>