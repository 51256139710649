@if (auth.user$ | async; as user) {
    <div class="h-fit lg:p-16 pt-[8.5rem] lg:pt-52">
        @if (customer?.couponCode) {
            <div @slideDown>
                <app-text class="text-center text-4xl lg:text-7xl font-black pb-12"
                          [value]="'Непокор бренд амбасадор'"></app-text>
            </div>
        }
        <div class="flex sm:flex-col md:flex-row pb-32 flex-wrap gap-10">
            <div class="mx-auto w-full lg:w-1/4 h-full px-5">
                <div class="border-2 border-green-600 rounded-3xl p-2 mx-auto">
                    @if (user.email === environment.admin) {
                        <div class="pt-3 pb-3">
                            <app-primary-button [value]="'Админ панел'"
                                                (click)="goToUrl(router, '/admin-panel')"
                            />
                        </div>
                    }
                    <div class="flex pb-3 sm:flex-col flex-wrap md:flex-row">
                        <img class="w-32 rounded-lg shadow-lg mx-auto" src="{{user.picture}}" alt="profile pic">
                        <div class="flex md:pl-3 flex-col pt-3 mx-auto">
                            <app-text [value]="'Е-маил: ' + user.email"></app-text>
                            <app-secondary-button
                                    [value]="'Одјави се'"
                                    (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"/>
                        </div>
                    </div>
                    <div id="personalInfo">
                        <div>
                            <h2 class="mb-0" id="flush-headingOne">
                                <button
                                        class="group relative flex w-full items-center rounded-none bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                                        type="button"
                                        data-te-collapse-init
                                        data-te-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                    Лични податоци
                                </button>
                            </h2>
                            <div
                                    id="flush-collapseOne"
                                    class="!visible hidden"
                                    aria-labelledby="flush-headingOne"
                                    data-te-parent="#personalInfo">
                                <div class="px-5 py-4">
                                    <div>
                                        <app-text [value]="'Име'"></app-text>
                                        <app-text-input
                                                [placeholder]="'Внеси име'"
                                                [control]="nameControl"></app-text-input>

                                    </div>
                                    <div>
                                        <app-text [value]="'Презиме'"></app-text>
                                        <app-text-input
                                                [placeholder]="'Внеси презиме'"
                                                [control]="surnameControl"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Телефонски број'"></app-text>
                                        <app-text-input [placeholder]="'Внеси телефонски број'"
                                                        [control]="phoneNumberControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Адреса'"></app-text>
                                        <app-text-input [placeholder]="'Внеси адреса'"
                                                        [control]="addressControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div>
                                        <app-text [value]="'Град'"></app-text>
                                        <app-text-input [placeholder]="'Внеси град на живеење'"
                                                        [control]="cityControl"
                                                        [type]="'required'"></app-text-input>
                                    </div>
                                    <div class="pt-3 pb-3">
                                        <app-primary-button [value]="'Ажурирај податоци'"
                                                            (click)="updateCustomer()"></app-primary-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sm:w-full lg:w-1/2 h-full px-5 lg:px-0 lg:pr-10">
                <div class="w-full h-full lg:pr-32">
                    <div class="border-2 border-green-600 rounded-3xl p-2 mx-auto h-full w-full">
                        <div class="bg-white py-8 mx-auto px-5">
                            <div class="max-w-4xl mx-auto">
                                <h1 class="text-3xl font-bold text-green-600 mb-6">Како До Финансиска Слобода - Изгради
                                    Успешен Бизнис
                                    со
                                    AloeVeraGel.mk</h1>
                                <p class="text-gray-700 text-lg mb-6">
                                    Нашиот бизнис модел е совршена комбинација од личен успех, тимска работа и
                                    висококвалитетни производи.
                                    Со
                                    <strong>AloeVeraGel.mk</strong> и Forever Living Products, ја имате можноста да
                                    изградите стабилна
                                    кариера и
                                    да бидете дел од глобална заедница која го поддржува здравјето, убавината и
                                    просперитетот.
                                </p>

                                <h2 class="text-2xl font-semibold text-gray-800 mb-4">Зошто да не изберете нас?</h2>
                                <p class="text-gray-700 mb-6">Со нашата уникатна платформа, може да остварите три клучни
                                    цели во
                                    животот:</p>

                                <div class="grid grid-cols-1 gap-6">
                                    <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                                        <h3 class="text-xl font-bold text-green-600 mb-3">Здравје</h3>
                                        <p class="text-gray-700">Внесете позитивна промена во животите на клиентите,
                                            помагајќи им да се
                                            чувствуваат подобро и посреќно.</p>
                                    </div>
                                    <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                                        <h3 class="text-xl font-bold text-green-600 mb-3">Финансиска Слобода</h3>
                                        <p class="text-gray-700">Бидете сопственици на вашиот приход. Заработете од
                                            вашиот труд и успехот на
                                            вашиот тим.</p>
                                    </div>
                                    <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                                        <h3 class="text-xl font-bold text-green-600 mb-3">Контрола на Времето</h3>
                                        <p class="text-gray-700">Организирајте го вашиот ден како што вие сакате со
                                            флексибилен
                                            распоред.</p>
                                    </div>
                                </div>

                                <h2 class="text-2xl font-semibold text-gray-800 mt-10 mb-4">Како до Успех?</h2>
                                <ol class="list-decimal list-inside text-gray-700 mb-6">
                                    <li class="mb-3"><strong>Започнете го вашиот пат:</strong> Споделете ги придобивките
                                        од нашите производи
                                        со
                                        пријателите и семејството.
                                    </li>
                                    <li class="mb-3"><strong>Создадете свој тим:</strong> Изградете мрежа од задоволни
                                        клиенти и
                                        соработници.
                                    </li>
                                    <li class="mb-3"><strong>Остварете лидерска позиција:</strong> Водете го вашиот тим
                                        кон успех и стабилен
                                        бизнис.
                                    </li>
                                </ol>

                                <blockquote
                                        class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 italic mb-6">
                                    „Оваа можност ни го смени животот. Од мал дополнителен приход, успеавме да изградиме
                                    независност и
                                    финансиска слобода. Придружете ни се – заедно ќе создадеме успешна приказна!“
                                </blockquote>

                                <div class="lg:w-60 mx-auto">
                                    <app-primary-button
                                            [value]="'Контактирај нè денес'"
                                            (click)="goToUrl(router, 'contact-us')"
                                    ></app-primary-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="bg-white pt-32 lg:pt-52 pb-14 mx-auto px-6 md:px-12 lg:px-20">
        <div class="max-w-4xl mx-auto">
            <h1 class="text-3xl font-bold text-green-600 mb-6">Како До Финансиска Слобода - Изгради Успешен Бизнис со
                AloeVeraGel.mk</h1>
            <p class="text-gray-700 text-lg mb-6">
                Нашиот бизнис модел е совршена комбинација од личен успех, тимска работа и висококвалитетни производи.
                Со
                <strong>AloeVeraGel.mk</strong> и Forever Living Products, ја имате можноста да изградите стабилна
                кариера и
                да бидете дел од глобална заедница која го поддржува здравјето, убавината и просперитетот.
            </p>

            <h2 class="text-2xl font-semibold text-gray-800 mb-4">Зошто да не изберете нас?</h2>
            <p class="text-gray-700 mb-6">Со нашата уникатна платформа, може да остварите три клучни цели во
                животот:</p>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                    <h3 class="text-xl font-bold text-green-600 mb-3">Здравје</h3>
                    <p class="text-gray-700">Внесете позитивна промена во животите на клиентите, помагајќи им да се
                        чувствуваат подобро и посреќно.</p>
                </div>
                <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                    <h3 class="text-xl font-bold text-green-600 mb-3">Финансиска Слобода</h3>
                    <p class="text-gray-700">Бидете сопственици на вашиот приход. Заработете од вашиот труд и успехот на
                        вашиот тим.</p>
                </div>
                <div class="bg-gray-100 p-6 rounded-lg shadow-md">
                    <h3 class="text-xl font-bold text-green-600 mb-3">Контрола на Времето</h3>
                    <p class="text-gray-700">Организирајте го вашиот ден како што вие сакате со флексибилен
                        распоред.</p>
                </div>
            </div>

            <h2 class="text-2xl font-semibold text-gray-800 mt-10 mb-4">Како до Успех?</h2>
            <ol class="list-decimal list-inside text-gray-700 mb-6">
                <li class="mb-3"><strong>Започнете го вашиот пат:</strong> Споделете ги придобивките од нашите производи
                    со
                    пријателите и семејството.
                </li>
                <li class="mb-3"><strong>Создадете свој тим:</strong> Изградете мрежа од задоволни клиенти и
                    соработници.
                </li>
                <li class="mb-3"><strong>Остварете лидерска позиција:</strong> Водете го вашиот тим кон успех и стабилен
                    бизнис.
                </li>
            </ol>

            <blockquote class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 italic mb-6">
                „Оваа можност ни го смени животот. Од мал дополнителен приход, успеавме да изградиме независност и
                финансиска слобода. Придружете ни се – заедно ќе создадеме успешна приказна!“
            </blockquote>

            <p class="text-gray-700 leading-relaxed mb-6 text-center">
                Контакт телефон
                <a href="tel:073857133" class="text-primary underline">
                    <strong>073/857-133</strong>
                </a>
            </p>

            <div class="lg:w-60 mx-auto">
                <app-primary-button
                        [value]="'Контактирај нè денес'"
                        (click)="goToUrl(router, 'contact-us')"
                ></app-primary-button>
            </div>
        </div>
    </div>
}
<app-toast [message]="toastMessage"></app-toast>
<app-footer></app-footer>