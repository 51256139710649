import {Component, OnInit} from '@angular/core';
import {CartService} from "./service/cart.service";
import {OrderItem} from "../../models/order-item";
import {Router} from "@angular/router";
import {animate, style, transition, trigger} from "@angular/animations";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.css'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})
export class CartComponent implements OnInit {

    orderItems: OrderItem[] = [];

    orderPrice: number = 0;

    deliveryExpenses: number = 150;

    totalPrice: number = 0;

    constructor(
        private readonly cartService: CartService,
        protected readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.updateCart();
    }

    updateCart() {
        this.orderItems = this.cartService.retrieveItemsFromLocalStorage();
        this.calculateTotal();
    }

    calculateTotal() {
        this.totalPrice = 0;
        this.orderPrice = 0;

        for (const orderItem of this.orderItems) {
            if (orderItem.product && orderItem.product.price && orderItem.quantity) {
                this.orderPrice += orderItem.product.price * orderItem.quantity;
            }
        }
        if (this.orderPrice < 1500) {
            this.totalPrice += this.deliveryExpenses;
        }

        this.totalPrice += this.orderPrice;
    }

    removeItemFromLocalStorage(index: number) {
        this.cartService.removeItemFromLocalStorage(index);
        this.updateCart();
    }

    updateOrderItemQuantity(index: number, newQuantity: number): void {
        const existingItems = this.getExistingItems();

        const orderItemToUpdate = existingItems[index];
        orderItemToUpdate.quantity = newQuantity;
        existingItems[index] = orderItemToUpdate;

        localStorage.setItem('cartItems', JSON.stringify(existingItems));
        this.updateCart()
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

    protected readonly goToUrl = goToUrl;
}